


























































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
  meta: {
    // sets document title
    title: "Tarjeta Tu Puente",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Conoce los beneficios destacados.",
      },
      keywords: { name: "keywords", content: "Puente" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8",
      },
    },
  },
})
export default class DestacadosComponent extends Vue {
  private comercios: any = [];
  private mounted() {
    this.getListadoDestacados();
  }

  private getListadoDestacados() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("/listado/beneficios/destacados")
      .then((res: any) => {
        this.comercios = res.data;
        for (let i = 0; i < this.comercios.length; i++) {
          if (this.comercios[i].foto) {
            this.comercios[i].foto =
              " https://d1rofaen6s22p4.cloudfront.net/" +
              this.comercios[i].foto;
          }else {
            this.comercios[i].foto = require("@/assets/logos/logo-default.png");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.$q.loading.hide();
      });
  }

  private irBeneficio(idComercio: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Beneficio" &&
      this.$router.currentRoute.params.name == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Beneficio",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
